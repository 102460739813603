<template>
  <div class="card-grid">
    <ChartCard chartType="bar" questionType="exercise_how_often" />
    <ChartCard chartType="bar" questionType="exercise_worktrip" />
    <ChartCard
      chartType="bar-horizontal"
      questionType="exercise_worktrip_hinderance"
      full
    />
  </div>
</template>
<script>
import ChartCard from "../chart-cards/ChartCard.vue";
export default {
  name: "exercise",
  metaInfo() {
    return {
      title: this.$t("dashboard.nav.exercise"),
    };
  },
  components: { ChartCard },
};
</script>
